import React from "react"
import { graphql } from "gatsby"
import SeO from "../components/seo"

const EnglishCertificates = ({ data }) => (
    <React.Fragment>     
        <SeO title="Πτυχία Αγγλικών" description="Πτυχία Αγγλικών" />
        <span><a href={process.env.GATSBY_XENES_GLOSSES_URL} target="_blank" rel="noopener noreferrer">Τίτλοι γλωσσομάθειας</a> βάσει ΑΣΕΠ.</span>
        
        <p style={{textAlign: 'right'}}>{data.markdownRemark.frontmatter.date}</p>
        <div style={{textAlign: 'justify', textJustify: 'inter-word'}} dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
    </React.Fragment>
)

export const query = graphql`
    query {
        markdownRemark(frontmatter: {title: {eq: "Πιστοποιητικά Αγγλικών"}}){
            frontmatter{
              title
              date
            }
            html
        }
    }
`

export default EnglishCertificates;